import { createRouter, createWebHistory } from "vue-router";

import NProgress from "nprogress/nprogress.js";

// Main layouts
import LayoutActiveWebsite from "@/layouts/variations/ActiveWebsite.vue";
import LayoutSimple from "@/layouts/variations/Simple.vue";
import { previewRoutes } from "@/router/preview";

// Frontend: Landing
import LandingHome from "@/views/starter/LandingViewHome.vue";
import LandingPreview from "@/views/starter/LandingPreview.vue";
import LayoutLanding from "@/layouts/LandingPageLayout.vue";
import { opportunityRoutes } from "@/router/opportunity";

const BackendDashboard = () => import("@/views/backend/pages/Dashboard.vue");
const BackendSpelling = () => import("@/views/backend/pages/Spelling.vue");
const BackendUrlGroup = () => import("@/views/backend/pages/UrlGroup.vue");
const BackendPagesGenericPricingPlans = () => import("@/views/pricing/PricingPlansView.vue");

// Auth
const AuthSignIn = () => import("@/views/auth/LogInView.vue");
const AuthSignUp = () => import("@/views/auth/SignUpView.vue");

const PasswordResetView = () => import("@/views/auth/PasswordResetView.vue");
const PrivacyPolicyView = () => import("@/views/starter/PrivacyPolicy.vue");
const TOSView = () => import("@/views/starter/TOS.vue");
const RefundsView = () => import("@/views/starter/Refunds.vue");
const PasswordResetConfirmView = () => import("@/views/auth/PasswordResetConfirmView.vue");


// Set all routes
const routes = [
    {
        path: "/",
        component: LayoutLanding,
        children: [
            {
                path: "",
                name: "landing",
                component: LandingHome,
                meta: {disableAuth: true, title: 'Rank Better with your Existing Content - PageWatch'},
                children: [
                    {
                        path: ":reportRoot/:websiteId",
                        name: "demoReport",
                        component: LandingPreview,
                        children: [
                            opportunityRoutes('demo')
                        ],
                        meta: {disableAuth: false},
                    },
                ]
            },
            {
                path: "login",
                name: "auth-login",
                component: AuthSignIn,
                meta: {disableAuth: true, hideNav: true, title: 'Login - PageWatch'},
            },
            {
                path: "signup",
                name: "auth-signup",
                component: AuthSignUp,
                meta: {disableAuth: true, hideNav: true, title: 'Sign Up - PageWatch'},
            },
            {
                path: "pw-reset",
                name: "auth-reminder",
                component: PasswordResetView,
                meta: {disableAuth: true, title: 'Password Reset - PageWatch'},
            },
            {
                path: "privacy",
                name: "privacy-policy",
                component: PrivacyPolicyView,
                meta: {disableAuth: true, title: 'Privacy Policy - PageWatch'},
            },
            {
                path: "tos",
                name: "tos",
                component: TOSView,
                meta: {disableAuth: true, title: 'Terms Of Service - PageWatch'},
            },
            {
                path: "refunds",
                name: "refunds",
                component: RefundsView,
                meta: {disableAuth: true, title: 'Refunds - PageWatch'},
            },
            {
                path: "pw-reset/confirm/",
                name: "password-reset-confirm",
                component: PasswordResetConfirmView,
                meta: {disableAuth: true, title: 'Password Reset - PageWatch'},
            },

        ],
    },
    {
        path: "/account",
        component: LayoutActiveWebsite,
        children: [
            {
                path: "dashboard",
                name: "accountDashboard",
                component: () => import("@/views/account/DashboardView.vue"),
                meta: {sidebar: 'user', title: 'Account Dashboard - PageWatch'},
            },
            {
                path: "subscription",
                name: "subscription",
                component: () => import("@/views/account/Subscription.vue"),
                meta: {sidebar: 'user', title: 'Account Subscription - PageWatch'},
            },
            {
                path: "invoices",
                name: "invoices",
                component: () => import("@/views/account/Invoices.vue"),
                meta: {sidebar: 'user', title: 'Invoices - PageWatch'},
            },
            {
                path: "new",
                name: "newSite",
                component: () => import("@/views/sites/NewSiteView.vue"),
                meta: {sidebar: 'user', title: 'Add New Site - PageWatch'},
            },
            {
                path: "oauth",
                name: "oauth",
                component: () => import("@/views/sites/NewSiteView.vue"),
                meta: {sidebar: 'user', title: 'Add New Site - PageWatch'},
            },
        ]
    },
    {
        path: "/:reportRoot/:websiteId",
        component: LayoutActiveWebsite,
        children: [
            {
                path: "dashboard",
                name: "dashboard",
                component: () => import("@/views/dashboard/DashboardView.vue"),
                meta: {title: 'Dashboard - PageWatch'},
                children: [
                    previewRoutes('dashboard'),
                    opportunityRoutes('dashboard')
                ]
            },
            {
                path: "explore",
                name: "explore",
                component: () => import("@/views/explore/LandingPagesView.vue"),
                meta: {title: 'Explore - PageWatch'},
                children: [
                    previewRoutes('explore')
                ]
            },
            {
                path: "summary",
                name: "summary",
                component: () => import("@/views/explore/SummaryView.vue"),
                meta: {title: 'Issue Summary - PageWatch'},
            },
            {
                path: "pricing",
                name: "pricing",
                component: BackendPagesGenericPricingPlans,
            },

            {
                path: "spelling/spelling",
                name: "spelling",
                component: () => import("@/views/spelling/SpellingView.vue"),
                meta: {title: 'Spelling - PageWatch'},
                children: [
                    previewRoutes('spelling')
                ]
            },
            {
                path: "spelling/capitalization",
                name: "capitalization",
                component: () => import("@/views/spelling/CapitalizationIssuesView.vue"),
                meta: {title: 'Capitalization - PageWatch'},
                children: [
                    previewRoutes('capitalization')
                ]
            },
            {
                path: "layout/text-overflow",
                name: "layoutOverflowingText",
                component: () => import("@/views/layout_issues/LayoutIssues.vue"),
                meta: {title: 'Layout - PageWatch'},
                children: [
                    previewRoutes('layout-text-overflow')
                ]
            },
            {
                path: "layout/overlapping-buttons",
                name: "layoutOverlappingButtons",
                component: () => import("@/views/layout_issues/LayoutIssues.vue"),
                meta: {title: 'Layout - PageWatch'},
                children: [
                    previewRoutes('layout-overlapping-buttons')
                ]
            },
            {
                path: "layout/missing-breakpoint",
                name: "layoutMissingBreakpoint",
                component: () => import("@/views/layout_issues/LayoutIssues.vue"),
                meta: {title: 'Layout - PageWatch'},
                children: [
                    previewRoutes('layout-missing-breakpoint')
                ]
            },
            {
                path: "layout/lsep",
                name: "layoutLsep",
                component: () => import("@/views/layout_issues/LayoutIssues.vue"),
                meta: {title: 'Layout - PageWatch'},
                children: [
                    previewRoutes('layout-lsep')
                ]
            },
            {
                path: "layout/etx",
                name: "layoutEtx",
                component: () => import("@/views/layout_issues/LayoutIssues.vue"),
                meta: {title: 'Layout - PageWatch'},
                children: [
                    previewRoutes('layout-etx')
                ]
            },
            {
                path: "layout/unicode",
                name: "layoutUnicode",
                component: () => import("@/views/layout_issues/LayoutIssues.vue"),
                meta: {title: 'Layout - PageWatch'},
                children: [
                    previewRoutes('layout-unicode')
                ]
            },
            {
                path: "layout/horizontal-scrolling",
                name: "layoutHorizontalScrolling",
                component: () => import("@/views/layout_issues/LayoutIssues.vue"),
                meta: {title: 'Layout - PageWatch'},
                children: [
                    previewRoutes('layout-horizontal-scrolling')
                ]
            },
            {
                path: "layout/overlapping-text",
                name: "layoutOverlappingText",
                component: () => import("@/views/layout_issues/LayoutIssues.vue"),
                children: [
                    previewRoutes('layout-overlapping-text')
                ]
            },
            {
                path: "layout/container-overflow",
                name: "layoutOverflowingContainer",
                component: () => import("@/views/layout_issues/LayoutIssues.vue"),
                meta: {title: 'Layout - PageWatch'},
                children: [
                    previewRoutes('layout-container-overflow')
                ]
            },
            {
                path: "layout/text-overflow",
                name: "layoutOverflowingText",
                component: () => import("@/views/layout_issues/LayoutIssues.vue"),
                meta: {title: 'Layout - PageWatch'},
                children: [
                    previewRoutes('layout-text-overflow')
                ]
            },

            {
                path: "layout/aspect-ratio",
                name: "layoutAspectRatio",
                component: () => import("@/views/layout_issues/LayoutIssues.vue"),
                meta: {title: 'Layout - PageWatch'},
                children: [
                    previewRoutes('layout-aspect-ratio')
                ]
            },
            {
                path: "spelling/dictionary",
                name: "dictionary",
                component: () => import("@/views/spelling/SpellingView.vue"),
                meta: {title: 'Dictionary - PageWatch'},
            },
            {
                path: "technical/broken-images",
                name: "brokenImages",
                component: () => import("@/views/broken_images/BrokenImages.vue"),
                meta: {title: 'Broken Images - PageWatch'},
                children: [
                    previewRoutes('broken-images')
                ]
            },
            {
                path: "technical/broken-links",
                name: "brokenLinks",
                component: () => import("@/views/broken_links/BrokenLinks.vue"),
                meta: {title: 'Broken Links - PageWatch'},
                children: [
                    previewRoutes('broken-links')
                ]
            },
            {
                path: "settings",
                name: "settings",
                component: () => import("@/views/settings/Settings.vue"),
                meta: {title: 'Settings - PageWatch'},
                children: [
                    previewRoutes('settings')
                ]
            },
            {
                path: "competitors",
                name: "addCompetitor",
                component: () => import("@/views/competitors/ManageCompetitors.vue"),
                meta: {title: 'Manage Competitors - PageWatch'},
                children: [
                    previewRoutes('competitors-manage-competitors')
                ]
            },
            {
                path: "competitors/:competitorAnalysisId",
                name: "competitorAnalysis",
                component: () => import("@/views/competitors/Analysis.vue"),
                meta: {title: 'Competitor Analysis - PageWatch'},
                children: [
                    previewRoutes('competitor-analysis')
                ]
            },
            {
                path: "technical/broken-resources",
                name: "brokenResources",
                component: () => import("@/views/broken_resources/BrokenResources.vue"),
                meta: {title: 'Broken Resources - PageWatch'},
                children: [
                    previewRoutes('broken-resources')
                ]
            },
            {
                path: "performance/slow-pages",
                name: "performanceSlowPages",
                component: () => import("@/views/performance/SlowPages.vue"),
                meta: {title: 'Slow Pages - PageWatch'},
                children: [
                    previewRoutes('slow-pages')
                ]
            },
            {
                path: "best-practice/outdated-copyright",
                name: "outdatedCopyright",
                component: () => import("@/views/content/Copyright.vue"),
                meta: {title: 'Best Practices - PageWatch'},
                children: [
                    previewRoutes('outdated-copyright')
                ]
            },
            {
                path: "insights",
                name: "pageInsights",
                component: () => import("@/views/insights/Insights.vue"),
                meta: {title: 'Insights - PageWatch'},
                children: [
                    previewRoutes('insights')
                ]
            },

            {
                path: "ranking/opportunities",
                name: "rankingOpportunities",
                component: () => import("@/views/ranking/OpportunitiesView.vue"),
                meta: {title: 'Keywords - PageWatch'},
                children: [
                    opportunityRoutes('ranking')
                ]
            },
            {
                path: "ranking/custom",
                name: "rankingOpportunitiesCustom",
                component: () => import("@/views/ranking/CustomOpportunitiesView.vue"),
                meta: {title: 'Custom Keywords - PageWatch'},
                children: [
                    opportunityRoutes('ranking-custom')
                ]
            },
            {
                path: "ranking/custom/new",
                name: "rankingAddKeyword",
                component: () => import("@/views/ranking/New.vue"),
                meta: {title: 'Add Keyword - PageWatch'},
                children: [
                    previewRoutes('ranking-keywords')
                ]
            },
            {
                path: "ranking/opportunities/:keywordGapId",
                name: "rankingOpportunitiesKeywordGap",
                component: () => import("@/views/ranking/OpportunitiesView.vue"),
                meta: {title: 'Keywords - PageWatch'},
                children: [
                    opportunityRoutes('ranking-gap')
                ]
            },
            {
                path: "ranking/pending/:keywordGapId",
                name: "rankingPendingKeywordGap",
                component: () => import("@/views/ranking/Pending.vue"),
                meta: {title: 'Keyword Gap - PageWatch'},
            },
            {
                path: "ranking/opportunities/completed",
                name: "rankingCompletedOpportunities",
                component: () => import("@/views/ranking/Completed.vue"),
                meta: {title: 'Completed Opportunities - PageWatch'},
            },
            {
                path: "visual/:category",
                name: "visualReview",
                component: () => import("@/views/visual/Visual.vue"),
                meta: {title: 'Visual Review - PageWatch'},
                children: [
                    previewRoutes('visual')
                ]
            },
            {
                path: "seo/internal-redirects",
                name: "internalRedirects",
                component: () => import("@/views/seo/InternalRedirects.vue"),
                meta: {title: 'Internal Redirects - PageWatch'},
                children: [
                    previewRoutes('internal-redirects'),
                ]
            },
            {
                path: "seo/missing-titles",
                name: "missingTitles",
                component: () => import("@/views/missing_titles/MissingTitles.vue"),
                meta: {title: 'Missing Titles - PageWatch'},
                children: [
                    previewRoutes('missing-titles'),
                ]
            },
            {
                path: "seo/missing-alt-tags",
                name: "missingImageAltTags",
                component: () => import("@/views/missing_image_alt_tags/MissingImageAltTags.vue"),
                meta: {title: 'Missing Alt Tags - PageWatch'},
                children: [
                    previewRoutes('missing-alt-tags')
                ]
            },
            {
                path: "best-practice/missing-favicon",
                name: "missingFavicon",
                component: () => import("@/views/missing_favicon/MissingFavicon.vue"),
                meta: {title: 'Missing Favicon - PageWatch'},
                children: [
                    previewRoutes('missing-favicon'),
                ]
            },
            {
                path: "seo/missing-descriptions",
                name: "missingDescriptions",
                component: () => import("@/views/missing_descriptions/MissingDescriptions.vue"),
                meta: {title: 'Missing Descriptions - PageWatch'},
                children: [
                    previewRoutes('missing-descriptions'),
                ]
            },
            {
                path: "technical/console-errors",
                name: "consoleErrors",
                component: () => import("@/views/console_logs/ConsoleErrors.vue"),
                meta: {title: 'Console Errors - PageWatch'},
                children: [
                    previewRoutes('console-errors')
                ]
            },
            {
                path: "technical/console-warnings",
                name: "consoleWarnings",
                component: () => import("@/views/console_logs/ConsoleWarnings.vue"),
                meta: {title: 'Console Warnings - PageWatch'},
                children: [
                    previewRoutes('console-warnings')
                ]
            },
            {
                path: "audit/open-issues",
                name: "auditOpenIssues",
                component: () => import("@/views/audit/OpenIssues.vue"),
                meta: {title: 'Open Issues - PageWatch'},
                children: [
                    previewRoutes('open-issues')
                ]
            },

        ],
    },
    {
        path: "/backend",
        redirect: "/backend/dashboard",
        component: LayoutSimple,
        children: [
            {
                path: "dashboard",
                name: "backend-dashboard",
                component: BackendDashboard,
            },
            {
                path: "spelling",
                name: "backend-spelling",
                component: BackendSpelling,
            },
            {
                path: "url-group/:groupId",
                name: "backend-url-group",
                component: BackendUrlGroup,
            },
        ]
    }
];

// Create Router
const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: "active",
    linkExactActiveClass: "active",
    scrollBehavior() {
        return {left: 0, top: 0};
    },
    routes,
});

// NProgress
/*eslint-disable no-unused-vars*/
NProgress.configure({showSpinner: false});

router.beforeResolve((to, from, next) => {
    NProgress.start();
    next();
});

router.afterEach((to, from) => {
    NProgress.done();
});
/*eslint-enable no-unused-vars*/

export default router;
