/*
 * Main and demo navigation arrays
 *
 * 'to' attribute points to the route name, not the path url
 */
import devices from "@/data/devices";

export default {
    user: function () {
        return [
            {
                name: "My Account",
                to: {name: "accountDashboard"},
                icon: "fa fa-user",
            },
            {
                name: "New Site",
                to: {name: "newSite"},
                icon: "fa fa-plus",
            },
            {
                name: "Billing",
                heading: true,
            },
            {
                name: "Subscription Plan",
                to: {name: "subscription"},
                icon: "fa fa-dollar-sign",
            },
        ]
    },
    main: function (websiteStore) {
        let website = websiteStore.website;
        if (!website) {
            return []
        }
        let scanStatus = websiteStore.scanStatus;

        if (scanStatus.site.status === 'error') {
            return [
                {
                    name: "Dashboard",
                    to: {name: "dashboard"},
                    icon: "si si-home",
                },
            ]
        }

        let auditMenu = [];
        let spellingIssueCount = website.issueStats.spelling;
        let inconsistentCapitalizationIssueCount = website.issueStats.inconsistent_capitalization;
        if (spellingIssueCount > 0 || inconsistentCapitalizationIssueCount > 0) {
            let sub = []
            if (spellingIssueCount > 0) {
                sub.push({
                    name: `Spelling Errors`,
                    to: {name: "spelling"},
                    icon: "",
                })
            }
            if (inconsistentCapitalizationIssueCount > 0) {
                sub.push({
                    name: `Inconsistent Capitalization`,
                    to: {name: "capitalization"},
                    icon: "",
                })
            }
            auditMenu.push({
                name: `Spelling/Grammar`,
                icon: "fa fa-spell-check",
                subActivePaths: "/r/:websiteId/spelling",
                sub: sub
            });
        }

        let brokenLinksIssueCount = website.issueStats.broken_links;
        let brokenResourcesIssueCount = website.issueStats.broken_resources;
        let consoleErrorsIssueCount = website.issueStats.console_errors;
        let consoleWarningsIssueCount = website.issueStats.console_warnings;
        let brokenImagesIssueCount = website.issueStats.broken_images;

        if (brokenResourcesIssueCount > 0 || consoleErrorsIssueCount > 0 || consoleWarningsIssueCount > 0 || brokenImagesIssueCount > 0 || brokenLinksIssueCount > 0) {
            let sub = []
            if (brokenResourcesIssueCount > 0) {
                sub.push({
                    name: `Broken Resources`,
                    to: {name: "brokenResources"},
                    icon: "",
                });
            }
            if (brokenLinksIssueCount > 0) {
                sub.push({
                    name: `Broken Links`,
                    to: {name: "brokenLinks"},
                    icon: "",
                });
            }
            if (consoleErrorsIssueCount > 0) {
                sub.push({
                    name: `Console Errors`,
                    to: {name: "consoleErrors"},
                    icon: "",
                });
            }
            if (consoleWarningsIssueCount > 0) {
                sub.push({
                    name: `Console Warnings`,
                    to: {name: "consoleWarnings"},
                    icon: "",
                });
            }
            if (brokenImagesIssueCount > 0) {
                sub.push({
                    name: `Broken Images`,
                    to: {name: "brokenImages"},
                    icon: "",
                });
            }

            auditMenu.push({
                name: `Technical Issues`,
                icon: "fa fa-code",
                subActivePaths: "/r/:websiteId/technical",
                sub: sub
            })
        }
        let internalRedirectsIssueCount = website.issueStats.internal_redirects;
        if (internalRedirectsIssueCount > 0 ||
            website.issueStats.missing_title > 0 ||
            website.issueStats.missing_description > 0 ||
            website.issueStats.missing_description ||
            website.issueStats.missing_image_alt_tag > 0
        ) {
            let sub = [];

            if (internalRedirectsIssueCount > 0) {
                sub.push({
                    name: `Internal Redirects`,
                    to: {name: "internalRedirects"},
                    icon: "",
                });
            }
            if (website.issueStats.missing_title > 0) {
                sub.push({
                    name: `Missing Title`,
                    to: {name: "missingTitles"},
                    icon: "",
                });
            }
            if (website.issueStats.missing_description > 0) {
                sub.push({
                    name: `Missing Description`,
                    to: {name: "missingDescriptions"},
                    icon: "",
                });
            }
            if (website.issueStats.missing_image_alt_tag > 0) {
                sub.push({
                    name: `Missing Alt Tags`,
                    to: {name: "missingImageAltTags"},
                    icon: "",
                });
            }
            auditMenu.push({
                name: `SEO Issues`,
                icon: "fab fa-google",
                subActivePaths: "/r/:websiteId/seo",
                sub: sub
            })
        }

        let l = website.issueStats.layout

        let layoutSub = []

        if (l.aspect_ratio > 0) {
            layoutSub.push({
                name: `Invalid Aspect Ratio`,
                to: {name: "layoutAspectRatio"},
                icon: "",
            });
        }
        if (l.button_overlap > 0) {
            layoutSub.push({
                name: `Overlapping Buttons`,
                to: {name: "layoutOverlappingButtons"},
                icon: "",
            });
        }
        if (l.narrow > 0) {
            layoutSub.push({
                name: `Missing Breakpoint`,
                to: {name: "layoutMissingBreakpoint"},
                icon: "",
            });
        }
        if (l.outside_window > 0) {
            layoutSub.push({
                name: `Overflowing Text`,
                to: {name: "layoutOverflowingText"},
                icon: "",
            });
        }
        if (l.lsep > 0) {
            layoutSub.push({
                name: `Invisible Characters`,
                to: {name: "layoutLsep"},
                icon: "",
            });
        }
        if (l.etx > 0) {
            layoutSub.push({
                name: `Invisible Characters`,
                to: {name: "layoutEtx"},
                icon: "",
            });
        }
        if (l.unsupported_unicode > 0) {
            layoutSub.push({
                name: `Unsupported Unicode`,
                to: {name: "layoutUnicode"},
                icon: "",
            });
        }
        if (l.horizontal_scrolling > 0) {
            layoutSub.push({
                name: `Horizontal Scrolling`,
                to: {name: "layoutHorizontalScrolling"},
                icon: "",
            });
        }
        if (l.text_overlap > 0) {
            layoutSub.push({
                name: `Overlapping Text`,
                to: {name: "layoutOverlappingText"},
                icon: "",
            });
        }
        if (l.overflow_h + l.overflow_w > 0) {
            layoutSub.push({
                name: `Overflowing Container`,
                to: {name: "layoutOverflowingContainer"},
                icon: "",
            });
        }
        if (layoutSub.length > 0) {
            auditMenu.push({
                name: `Layout Issues`,
                icon: "fa fa-rectangle-list",
                subActivePaths: "/r/:websiteId/layout",
                sub: layoutSub
            });
        }

        if (website.issueStats.copyright > 0 || website.issueStats.missing_favicon > 0) {
            let sub = []
            if (website.issueStats.copyright > 0) {
                sub.push({
                    name: `Outdated Copyright`,
                    to: {name: "outdatedCopyright"},
                    icon: "",
                })
            }
            if (website.issueStats.missing_favicon > 0) {
                sub.push({
                    name: `Missing Favicon`,
                    to: {name: "missingFavicon"},
                    icon: "",
                });
            }

            auditMenu.push({
                name: `Best Practices`,
                icon: "fa fa-bullseye",
                subActivePaths: "/r/:websiteId/best-practice",
                sub: sub
            });
        }

        let p = website.issueStats.performance

        let performanceSub = []

        if (p.slow_pages > 0) {
            performanceSub.push({
                name: `Slow Pages`,
                to: {name: "performanceSlowPages"},
                icon: "",
            });
        }
        if (p.large_images > 0) {
            performanceSub.push({
                name: `Large Images`,
                to: {name: "layoutOverlappingButtons"},
                icon: "",
            });
        }
        if (performanceSub.length > 0) {
            auditMenu.push({
                name: `Performance Issues`,
                icon: "fa fa fa-rocket",
                subActivePaths: "/r/:websiteId/performance",
                sub: performanceSub
            });
        }

        // if (auditMenu.length === 0) {
        //     auditMenu.push({
        //         name: `No Issues`,
        //         icon: "fa fa fa-check",
        //         to: {name: "auditOpenIssues"},
        //     });
        // }
        let menuItems = [
            {
                name: "Dashboard",
                to: {name: "dashboard"},
                icon: "si si-home",
            },

        ]
        // menuItems = menuItems.concat([
        //     {
        //         name: "Optimization",
        //         heading: true,
        //     },
        // ]);
        //
        // let pageCategories = website.issueStats.page_categories || [];
        // if (pageCategories.length > 0) {
        //     menuItems.push({
        //         name: "Page Insights",
        //         icon: "fa fa-wand-magic-sparkles",
        //         to: {name: "pageInsights"},
        //     });
        // }

        let sub = []
        let competitors = website.issueStats.competitors || [];
        for (let competitor of competitors) {
            let name = competitor.competitor.name;
            // captitalize name
            name = name[0].toUpperCase() + name.slice(1);
            sub.push({
                name: name,
                to: {name: "competitorAnalysis", params: {competitorAnalysisId: competitor.shortuuid}},
                icon: "",
            });
        }

        menuItems = menuItems.concat([
            {
                name: "Ranking",
                heading: true,
            },
        ])

        // if (sub.length > 0){
        //     menuItems = menuItems.concat([
        //         {
        //             name: "Competitors",
        //             icon: "fa fa-users",
        //             subActivePaths: "/r/:websiteId/competitors/",
        //             sub: sub
        //         }
        //     ]);
        // }

        menuItems = menuItems.concat([
            // {
            //     name: 'Opportunities',
            //     icon: "fa fa-arrow-up-right-dots",
            //     subActivePaths: "/r/:websiteId/ranking/",
            //     sub: [
            //         {
            //             name: "Active ",
            //             to: {name: "rankingOpportunities"},
            //         },
            //         {
            //             name: "Completed",
            //             to: {name: "rankingCompletedOpportunities"},
            //         }
            //     ]
            // },
            {
                name: "Opportunities",
                to: {name: "rankingOpportunities"},
               icon: "fa fa-arrow-up-right-dots",
            }       ,
            {
                name: "Competitor Research",
                to: {name: "rankingOpportunitiesCustom"},
                icon: "fa fa-chart-pie",
            },

        ]);

        menuItems = menuItems.concat([
                        {
                name: "Site Audit",
                heading: true,
            },
            {
                name: "All Pages",
                to: {name: "explore"},
                icon: "fa fa-sitemap",
            },
            ]);
                menuItems = menuItems.concat(auditMenu);


        return menuItems;

    }
    ,
}
;
