<script setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from 'vue-router'
import { useActiveDeviceRenderStore } from "@/stores/activeDeviceRender";
import { storeToRefs } from "pinia";
import { useWebsiteStore } from "@/stores/website";
import { useUserStore } from "@/stores/user";
import { gql } from "graphql-tag";
import { useQuery } from "@vue/apollo-composable";
import DevicePreview from "@/components/DevicePreview.vue";
import HighlightBox from "@/views/preview/partials/HighlightBox.vue";
import RadialProgress from "vue3-radial-progress";
import { useIssuesStore } from "@/stores/issues";
import InnerIssueSection from "@/views/preview/sections/InnerIssueSection.vue";

const activeDeviceRender = useActiveDeviceRenderStore();
const router = useRouter()
const route = useRoute()
let fromPath = route.meta.origin
const websiteStore = useWebsiteStore();
const {scanStatus, lastUpdated, website} = storeToRefs(websiteStore);
import IssueSection from "@/views/preview/sections/IssueSection.vue";
import PerformanceIssue from "@/views/preview/partials/PerformanceIssue.vue";
import HttpStatusCodeBadge from "@/components/HttpStatusCodeBadge.vue";
import Keywords from "@/views/opportunity/Keywords.vue";
import ImportantTerms from "@/views/opportunity/ImportantTerms.vue";
import LoadingPlaceholder from "@/components/LoadingPlaceholder.vue";

const issuesStore = useIssuesStore();
const {
  brokenLinkMarks,
  crawlErrors,
  cloudFlareError,
  consoleWarnings,
  internalRedirectMarks,
  brokenImageMarks,
  missingAltTagImageMarks,
  brokenResources,
  inconsistentCapitalizationMarks,
  consoleErrors,
  layoutErrors,
  seoWarnings,
  performanceScore,
  spellingMarks,
  contentIssueMarks,
  contentErrors,
  seoErrors
} = storeToRefs(issuesStore);

const highlightDetails = ref({
  content: {
    title: 'What can you learn from the competitor\'s page?',
    text_1: '.',
    text_2: '',
    url: '',
  },
  variant: 'info',
  style: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    'z-index': '21000',
    visibility: 'hidden',
  },
  index: 0,
  box: 0,
  nextLink: {
    query: {},
    show: false,
    style: {
      color: 'white',
      opacity: 0.8
    }
  },
  prevLink: {
    query: {},
    show: false,
    style: {
      color: 'white',
      opacity: 0.8
    }
  }
})
const highlightBox = ref(null);

const maxWidth = computed(() => {
  if (grid.xl) {
    return 600;
  }
  if (grid.lg) {
    return 368;
  }
  return Math.min(screen.width - 100, 350)
})

onMounted(() => {
      let myModal = new bootstrap.Modal(document.getElementById('modal-block-tabs-altx'), {})
      let myModalEl = document.getElementById('modal-block-tabs-altx')
      myModalEl.addEventListener('hidden.bs.modal', function (event) {
        if (fromPath) {

          let path = {name: 'rankingOpportunities'}
          if (route.path.includes('dashboard')) {
            path = {name: 'dashboard'}
          }
          if (route.path.includes('custom')) {
            path = {name: 'rankingOpportunitiesCustom'}
          }
          if (route.path.startsWith('/d/')) {
            path = {name: 'landing'}
          }

          if (fromPath.includes('dashboard') && !fromPath.includes('/preview/') && !fromPath.includes('/opportunity/')) {
            router.push(fromPath)
          } else {
            router.push(path)
          }

        }
      })
      myModal.show()
    }
)
onBeforeUnmount(() => {
  let myModalEl = document.getElementById('modal-block-tabs-altx')
  let modal = bootstrap.Modal.getInstance(myModalEl)

  if (myModalEl.classList.contains('show')) {
    fromPath = '';
    modal.hide()
  }
});

const queryVariables = ref({
  shortuuid: '',
})
const loaded = ref(false)

const mainRender = ref({})
const opportunity = ref({})
const chosenOpportunity = ref({})
const showAnsweredQuestions = ref(false)
const showUnRelatedQuestions = ref(false)
const gaps = ref([])
const advantages = ref([])
const questions = ref([])
const keywordDensity = ref([])
const contentStats = ref({})

function highlightEntry(entry) {
  let index = parseInt(route.query.i, 10) || 1;

  let occurrences = JSON.parse(JSON.stringify(entry.occurrences))
  let occurrence = entry.occurrences[index - 1];

  if (!occurrence) {
    return
  }

  let query = {}
  query[entry.query] = entry.id;
  occurrences.sort((a, b) => {
    if (a.box.top > b.box.top) {
      return 1
    }
    if (a.box.top < b.box.top) {
      return -1
    }
    return 0
  })
  highlightDetails.value.nextLink.query = {};
  highlightDetails.value.prevLink.query = {};
  Object.assign(highlightDetails.value.nextLink.query, query);
  Object.assign(highlightDetails.value.prevLink.query, query);
  highlightDetails.value.nextLink.query.i = index + 1
  highlightDetails.value.prevLink.query.i = index - 1
  highlightDetails.value.nextLink.show = true
  highlightDetails.value.prevLink.show = true
  if (index === 1) {
    highlightDetails.value.prevLink.show = false
  }
  if (index === occurrences.length) {
    highlightDetails.value.nextLink.show = false
  }

  highlightDetails.value.content = occurrence.highlightDetails;
  if (mainRender.value.ref) {
    mainRender.value.ref.scrollToBox(occurrence.box)
  }
}

function setHighlightDetails() {
  let query = route.query;
  // clearHighlights(mainRender.value)
  for (let gap of gaps.value) {
    gap.selected = false
  }
  highlightDetails.value.style.visibility = 'hidden'
  highlightDetails.value.variant = 'info'
  highlightDetails.value.content = {}
  if (query.topic) {
    chosenOpportunity.value = gaps.value[parseInt(query.topic, 10) - 1]
    if (!chosenOpportunity.value){
      return
    }
    highlightDetails.value.content['title'] = 'Recommended improvement'
    highlightDetails.value.content['text_1'] = chosenOpportunity.value.recommendation
    scrollToFirstBox()

    gaps.value[parseInt(query.topic, 10) - 1].selected = true
  } else if (query.advantage) {
    chosenOpportunity.value = advantages.value[parseInt(query.advantage, 10) - 1]
    if (!chosenOpportunity.value){
      return
    }
    highlightDetails.value.content['title'] = 'Advantages of your page'
    highlightDetails.value.content['text_1'] = chosenOpportunity.value.client
    scrollToFirstBox()
  } else if (query.question) {
    chosenOpportunity.value = questions.value[parseInt(query.question, 10) - 1]
    if (!chosenOpportunity.value){
      return
    }
    highlightDetails.value.content['title'] = 'Recommendation'
    highlightDetails.value.content['text_1'] = chosenOpportunity.value.recommendation
    if (chosenOpportunity.value.article_fit < 3) {
      highlightDetails.value.content['title'] = ''
      highlightDetails.value.content['text_1'] = `The question below appears when searching for "${opportunity.value.keyword}", but is not directly related to your page.`
    }
    if (chosenOpportunity.value.answered) {
      highlightDetails.value.content['text_1'] = `Your article already does a good job of answering the question below that appears when searching for "${opportunity.value.keyword}".`
    }
    scrollToFirstBox()

  } else {
    let issue = issuesStore.getIssueFromQuery(route.query);
    highlightDetails.value.variant = 'error'
    if (issue.entry) {
      highlightEntry(issue.entry)
    } else {
    }
    // devicePreview.value.highlightEntries();


    scrollToFirstBox()
  }
}

watch(() => route.query, (query) => {
  setHighlightDetails()
})

function getDomainFromURL(url) {
  try {
    const parsedURL = new URL(url);
    // replace start if www. is present
    let r = parsedURL.hostname;
    if (r.startsWith('www.')) {
      r = r.replace('www.', '');
    }
    return r;

  } catch (error) {
    return '';
  }
}


function mapLines(lines) {
  if (!lines) {
    return []
  }

  return lines.map((line, index) => {
    return {"text": line.text, id: index, tag: line.node.toLowerCase(), selected: false}
  })
}

const SELECT_GAP_ANALYSIS = gql`
        query GapAnalysis($shortuuid: String!) {
          ai_gapanalysis (where: {shortuuid: {_eq: $shortuuid}}) {
            id
            created_at
            main_keyword
            url
            gaps
            people_also_ask
            main_lines
            competitors
            summary
            device_render_main{
              id
              device_id
              status
              screenshots
              pageversion{
                id
                lighthouse
                canonical_page{
                  id
                  external_id
                  path
                  landing_page
                  website{
                    id
                    url
                  }
                }
              }
            }
          }
        }`

const {result, refetch} = useQuery(SELECT_GAP_ANALYSIS, queryVariables, () => ({
      enabled: (!!(website.value && website.value.id)) && activeDeviceRender.loaded,
    })
)
let analysis = {};
watch(
    result,
    data => {
      analysis = data.ai_gapanalysis[0];
      if (!analysis) {
        return
      }

      mainRender.value = {
        id: analysis.device_render_main.id,
        status: analysis.device_render_main.status === 'error' ? 'completed' : analysis.device_render_main.status,
        deviceId: analysis.device_render_main.device_id,
        screenshot: analysis.device_render_main.screenshots['main'] || {},
        screenshotFull: analysis.device_render_main.screenshots['full'] || {},
        lines: []
      }

      opportunity.value = {
        id: analysis.id,
        keyword: analysis.main_keyword,
        target_page: analysis.url,
        competitorUrl: analysis.url,
        competitorDomain: getDomainFromURL(analysis.url),
        url: activeDeviceRender.page.url,
        pageId: activeDeviceRender.page.url.externalId,
        score: activeDeviceRender.page.score,
      }

      gaps.value = []
      let i = 0;
      for (let item of sortedGaps(analysis.gaps['gaps'])) {
        i += 1;
        gaps.value.push({
          id: i,
          url: item.url,
          domain: getDomainFromURL(item.url),
          selected: route.query.topic === `${i}`,
          title: item.title,
          label: 'Why Google might rank the competitor\'s page higher',
          competitor: item.competitor,
          client: item.client,
          recommendation: item.recommendation,
          importance: item.importance,
          fix: item.fix,
          fix_html: item.fix_html,
          client_lines: item.client_lines || [],
          client_highlights: item.client_highlights || [],
          competitor_lines: item.competitor_lines || [],
          competitor_highlights: item.competitor_highlights || [],
          reason: item.reason,
          recommendation_section: item.recommendation_section,
          recommendation_details: item.recommendation_details,
          recommendation_box: item.recommendation_box,
          keyword_relation: item.keyword_relation,
        })

      }

      advantages.value = []
      i = 0;
      for (let item of (analysis.gaps['advantages'] || [])) {
        i += 1;
        advantages.value.push({
          id: i,
          url: item.url,
          domain: getDomainFromURL(item.url),
          title: item.title,
          label: 'Advantages of your page',
          competitor: item.competitor,
          client: item.client,
          importance: item.importance,
          client_lines: item.client_lines || [],
          client_highlights: item.client_highlights || [],
          competitor_lines: item.competitor_lines || [],
          competitor_highlights: item.competitor_highlights || [],
          keyword_relation: item.keyword_relation,
        })

      }

      questions.value = []
      i = 0;
      for (let item of (analysis.people_also_ask['questions'] || [])) {

        if (getDomainFromURL(item.google_url) === getDomainFromURL(opportunity.value.url)) {
          continue
        }

        if (!item.recommendation || item.recommendation.length < 5 || !item.recommendation_section) {
          continue
        }

        i += 1;
        questions.value.push({
          id: i,
          title: item.title,
          label: 'Unanswered Question',
          answered: item.answered === 'yes',
          article_fit: item.article_fit,
          google_url: item.google_url,
          google_title: item.google_title,
          google_answer: item.google_answer,
          example_answer: item.example_answer,
          recommendation: item.recommendation,
          recommendation_section: item.recommendation_section,
          recommendation_details: item.recommendation_details,
          recommendation_box: item.recommendation_box,
          box: item.box,
          reason: item.reason,
          client_lines: item.client_lines || [],
          competitor_lines: [],
          competitor_highlights: [],
          client_highlights: item.client_highlights,
        })

        if (questions.value.length >= 8) {
          break
        }

      }

      keywordDensity.value = []
      for (let item of (analysis.summary['keyword_density'] || [])) {
        keywordDensity.value.push({
          keyword: item.keyword,
          main: item.main,
          heading: item.heading,
        })
      }

      // find the median value in this array
      let median = (v) => {
        let arr = [...v]
        arr.sort((a, b) => a - b);
        let mid = Math.floor(arr.length / 2);
        return arr.length % 2 !== 0 ? arr[mid] : (arr[mid - 1] + arr[mid]) / 2;
      }

      contentStats.value = {
        wordCount: analysis.summary['word_count'],
        headingCount: analysis.summary['heading_count'],
      }

      loaded.value = true
      setHighlightDetails();
      // chosenOpportunity.value = gaps.value[0]
    }
)

let sortedGaps = ((gaps) => {
  let copiedGaps = [];
  Object.assign(copiedGaps, gaps);
  return copiedGaps.sort((a, b) => {
    let mapping = {
      'high': 3,
      'medium': 2,
      'low': 1
    }
    return (mapping[b.importance] || 0) - (mapping[a.importance] || 0)
  })
})

const openQuestions = computed(() => {
  return questions.value.filter((q) => !q.answered && q.article_fit >= 3).slice(0, 8)
})
const answeredQuestions = computed(() => {
  return questions.value.filter((q) => q.answered).slice(0, 8)
})
const unRelatedQuestions = computed(() => {
  return questions.value.filter((q) => q.article_fit < 3).slice(0, 8)
})

watch(() => route.params, (newVal, oldVal) => {
  queryVariables.value.shortuuid = route.params.opportunityId

}, {immediate: true})


function clearHighlights(render) {
  addHighlights(render)
  if (render.lines) {
    for (let line of render.lines) {
      line.selected = false
    }
  }
}

function addHighlights(render) {
  let boxes = []
  let query = {}
  let count = 0;
  let index = 0;

  if (render.ref) {

    let highlights = []

    index = 1;
    for (let question of openQuestions.value) {
      let q = {
        'i': question.id,
        'scroll': '1',
        'question': question.id,
      }
      let box = {
        left: question.recommendation_box.left,
        top: question.recommendation_box.top - 30,
        width: question.recommendation_box.width,
        height: question.recommendation_box.height,
      }
      if ((box.height > 2000) || (box.left === 0 && box.top === 0)) {
        box.height = 1
        box.width = 390
        box.left = 2
        box.top = 100

        // if this is not the last highlight, skip it
        if (count === 0 && index !== highlights.length) {
          continue
        }
      }
      highlights.push({
        q: q,
        id: `highlight-box-question-${question.id}`,
        box: box,
        icon: 'fa fa-question-circle',
        variant: {
          'type': 'topic',
        }
      })
      index += 1;
    }

    index = 1;
    for (let gapEntry of gaps.value) {
      let q = {
        'i': index,
        'scroll': '1',
        'topic': index,
      }

      let box = {
        left: gapEntry.recommendation_box.left,
        top: gapEntry.recommendation_box.top - 30,
        width: gapEntry.recommendation_box.width,
        height: gapEntry.recommendation_box.height,
      }
      if ((box.height > 2000) || (box.left === 0 && box.top === 0)) {
        box.height = 1
        box.width = 390
        box.left = 2
        box.top = 100

        // if this is not the last highlight, skip it
        if (count === 0 && index !== highlights.length) {
          continue
        }
      }

      highlights.push({
        q: q,
        id: `highlight-box-topic-${index}`,
        box: box,
        icon: 'fa fa-arrow-up-right-dots',
        variant: {
          'type': 'topic',
        }
      })
      index += 1;
    }

    if (currentSection.value === 'advantage') {
      index = parseInt(route.query.advantage, 10) ;
      let advantage = advantages.value[parseInt(route.query.advantage, 10) - 1]
      let c = 0;
      for (let client_highlight of advantage.client_highlights) {
        c += 1;
        let q = {
          'i': index,
          'scroll': '1',
          'advantage': index,
        }
        let id = `highlight-box-advantage-${index}`
        if (c > 1) {
          id = `highlight-box-advantage-${index}-${c}`
        }
        highlights.push({
          q: q,
          id: id,
          box: client_highlight.box,
          icon: 'fa fa-thumbs-up',
          variant: {
            'type': 'rough',
            'style': 'highlight',
            'color': 'rgba(246,206,4,0.24)',
          }
        })
      }
    }

    let ratio = render.ref.ratio();

    for (let annotation of highlights) {
      index += 1;
      let box = annotation.box;
      count += 1;
      let q = annotation.q
      let id = `highlight-box-inactive-${count}`
      if (route.query.topic) {
        id = `topic-${count}`
      }
      if (route.query.advantage) {
        id = `advantage-${count}-${route.query.advantage}`
      }
      if (route.query.keywords) {
        id = `keywords-${count}`
      }
      id = annotation.id;
      boxes.push({
        'id': id,
        'key': id,
        'i': `${index}`,
        'box': box,
        'context': 'dffd',
        'highlight': false,
        'level': '',
        'active': route.query.i === `${index}`,
        'visible': true,
        'query': q,
        'style': {
          'visibility': 'visible',
          'left': `${box.left * ratio}px`,
          'top': `${box.top * ratio}px`,
          'width': `${box.width * ratio}px`,
          'height': `${box.height * ratio}px`,
        },
        'icon': annotation.icon,
        'variant': annotation.variant
      })
    }

    let issues = brokenLinkMarks.value
        .concat(internalRedirectMarks.value)
        .concat(brokenImageMarks.value)
        .concat(missingAltTagImageMarks.value)
        .concat(layoutErrors.value)
        .concat(spellingMarks.value)
        .concat(contentIssueMarks.value)
        .concat(inconsistentCapitalizationMarks.value);


    let activeIssue = issuesStore.getIssueFromQuery(route.query).entry;

    count = 0;
    for (let issue of issues) {

      let index = 0;
      for (let occurrence of issue.occurrences) {
        if (!occurrence) {
          continue
        }
        count += 1;
        index += 1;

        let box = {}
        Object.assign(box, occurrence.box);
        let q = {}
        Object.assign(q, query);
        q['i'] = index;
        q[issue.query] = issue.id;

        if (occurrence.id === 'text_overlap') {
          box.left = Math.min(occurrence.box.left, occurrence.box2.left);
          box.top = Math.min(occurrence.box.top, occurrence.box2.top);
          box.width = Math.max(occurrence.box.left + occurrence.box.width, occurrence.box2.left + occurrence.box2.width) - box.left;
          box.height = Math.max(occurrence.box.top + occurrence.box.height, occurrence.box2.top + occurrence.box2.height) - box.top;
        }
        if (box.width < 12) {
          box.left -= 6;
          box.top -= 6;
          box.width += 12;
          box.height += 12;
        }

        let variant = {
          'type': 'box',
          'color': 'red',
          'sentiment': 'negative',
        }
        if (occurrence.variant) {
          variant = occurrence.variant;
        }

        let id = `highlight-box-inactive-${count}`
        if (activeIssue && activeIssue.id === issue.id) {
          id = `highlight-box-${index}`
        }

        boxes.push({
          'id': id,
          'key': id,
          'i': `${index}`,
          'highlight': false,
          'priority': issue.priority,
          'active': activeIssue && activeIssue.id === issue.id,
          'sentiment': variant.sentiment,
          'box': box,
          'style': {
            'visibility': 'visible',
            'left': `${box.left * ratio}px`,
            'top': `${box.top * ratio}px`,
            'width': `${box.width * ratio}px`,
            'height': `${box.height * ratio}px`,
          },
          'query': q,
          'variant': variant
        })
      }
    }

    render.ref.showBoxes(boxes)
  }
}

function scrollToFirstBox() {
  let shouldScroll = false;

  if (!mainRender.value.ref) {
    return
  }

  if (currentSection.value === 'audit') {
    let issue = issuesStore.getIssueFromQuery(route.query).entry;
    if (issue) {
      highlightEntry(issue)
    }
    return
  } else if (currentSection.value === 'keywords') {
    // find the topmost recommendation box
    // copy the array
    // let copiedGaps = JSON.parse(JSON.stringify(gaps.value));
    // let clientBox = copiedGaps.filter((g) => g.recommendation_box.height < 1000).sort((a, b) => a.recommendation_box.top - b.recommendation_box.top)[0].recommendation_box;
    // if (clientBox && (clientBox.height < 1000)) {
    //   mainRender.value.ref.scrollToBox(clientBox)
    //   shouldScroll = true
    // }
  } else {
    if (mainRender.value.lines.length > 0) {
      let lineNumber = chosenOpportunity.value.client_lines[0]
      if (lineNumber >= mainRender.value.lines.length) {
        lineNumber = mainRender.value.lines.length - 1
      }
      mainRender.value.ref.scrollToLine(lineNumber)
      shouldScroll = true
    } else {
      let clientBox = (chosenOpportunity.value.recommendation_box || {});

      if (currentSection.value === 'advantage') {
        clientBox = (chosenOpportunity.value.client_highlights[0] || {}).box || {};
      }

      if (clientBox && (clientBox.height < 1000)) {
        mainRender.value.ref.scrollToBox(clientBox)
        shouldScroll = true
      }
    }
  }

  if (!shouldScroll) {
    onScrollToEntryCompleted()
  }

}

function scroll(render, dir) {
  render.ref.scroll(dir)
  highlightDetails.value.style.visibility = 'hidden'
}


let progressColor = ((page) => {
  if (page.score >= 80) {
    return 'green'
  }
  if (page.score >= 50) {
    return 'orange'
  }
  return 'red'

})
let highlightsAdded = false;

function onScrollToEntryCompleted() {

  if (!highlightsAdded) {
    highlightsAdded = true;
    addHighlights(mainRender.value)
  }

  if (route.query.keywords) {
    return
  }

  addHighlights(mainRender.value)

  if (route.query.topic || route.query.advantage || route.query.question) {

    setTimeout(() => {
      let section = 'topic';
      let index = route.query.topic;
      if (route.query.advantage) {
        section = 'advantage'
        index = route.query.advantage
        addHighlights(mainRender.value)
      }
      if (route.query.question) {
        section = 'question'
        index = route.query.question
      }
      if (highlightDetails.value.content['title']) {
        highlightBox.value.showHighlightDetails(`highlight-box-${section}-${index}`)
      }

    }, 100)


  } else {
    if (highlightDetails.value.content.title) {
      let index = parseInt(route.query.i, 10) || 1;
      highlightBox.value.showHighlightDetails(`highlight-box-${index}`)
    }
  }
}

function onManualScroll() {
  highlightDetails.value.style.visibility = 'hidden'
}

let competitorUrl = computed(() => {
  if (route.query.question) {
    return chosenOpportunity.value.google_url
  }
  return opportunity.value.competitorUrl
})

let currentSection = computed(() => {
  if (route.query.topic) {
        if (!chosenOpportunity.value){
      return 'keywords'
    }
    return 'topic'
  }
  if (route.query.advantage) {
    return 'advantage'
  }
  if (route.query.question) {
    return 'question'
  }
  if (route.query.keywords) {
    return 'keywords'
  }
  return 'audit'
})

let onClientLoaded = (() => {
  setHighlightDetails()
  scrollToFirstBox()
});


let combinedSeoIssues = computed(() => {
  return seoErrors.value.concat(seoWarnings.value)
})

</script>

<template>

  <div
      class="modal load-transition"
      id="modal-block-tabs-altx"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal-block-tabs-alt"
      aria-hidden="true"
  >
    <div v-show="false" style="position:absolute;top:0%;left:50%">
      <div class="loading-container">

    <span class="spinner-border text-primary ms-2 me-2 text-muted"
          style="font-size:18px;height:28px;width:28px;color:white !important;">      </span>
      </div>
    </div>
    <div
        class="modal-dialog load-transition forced-large-modal modal-xl"
        role="document">
      <div id="preview-modal" class="modal-content load-transition " style="background-color:#EBEEF2">

        <div class="block-header block-header-rounded text-nowrap">

          <b>Ranking Opportunity</b>
          <div class="ms-2">
            -&gt;
          </div>
          <div class="ms-2 overflow-text-ellipsis">
            <a class="external-link-plain" :href="opportunity.url" target="_blank"> {{ opportunity.url }}</a>


          </div>
          <button
              type="button"
              class="btn-block-option ms-auto"
              data-bs-dismiss="modal"
              aria-label="Close"
          >
            <i class="fa fa-fw fa-times"></i>
          </button>
        </div>

        <div class="container" v-if="loaded">
          <highlight-box ref="highlightBox" :highlight-details="highlightDetails"></highlight-box>

          <div class="row mt-2">

            <div class="col col-12 col-md-8 col-xl-9">

              <div class="container text-center ps-0">

                <div class="row">
                  <div class="col mt-1 d-none d-xl-block" style="margin-right:-92px">

                    <table>
                      <tbody>
                      <tr>
                        <td></td>
                        <td>


                        </td>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-table-cell" style="width:30px">
                          <button
                              type="button"
                              class="btn btn-sm btn-alt-secondary mb-1 ms-3 ms-xl-0"
                              v-tooltip="`Scroll Up`"
                              @click="scroll(mainRender, 'up')"
                          >
                            <i class="fa fa-fw fa-up-long"/>
                          </button>
                          <br>
                          <button
                              type="button"
                              class="btn btn-sm btn-alt-secondary mb-1  ms-3 ms-xl-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              v-tooltip="`Scroll Down`"
                              @click="scroll(mainRender,'down')"
                          >
                            <i class="fa fa-fw fa-down-long"/>
                          </button>
                          <br>
                          <a
                              type="button"
                              :href="opportunity.url"
                              target="_blank"
                              class="btn btn-sm btn-alt-secondary mt-1  ms-3 ms-xl-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              :title="`${opportunity.url}`"
                          >
                            <i class="fa fa-link"/>
                          </a>
                        </td>

                        <td class="text-center">
                          <div style="position: relative;white-space: nowrap;min-width:220px;">
                            <device-preview :max-width="880" :max-height="680" v-bind="mainRender"
                                            :disable-screenshot-main="true"
                                            style="margin:0;padding:0"
                                            @on-scroll-to-entry-completed="onScrollToEntryCompleted"
                                            @on-manual-scroll="onManualScroll"
                                            @loaded="onClientLoaded"
                                            :ref="(el) => {mainRender.ref = el}"></device-preview>
                            <br>

                          </div>


                        </td>


                      </tr>
                      </tbody>
                    </table>


                  </div>

                  <div class="col ps-0  opportunity-content">
                    <table class="w-100">
                      <tbody>
                      <tr v-if="['topic', 'advantage', 'question'].includes(currentSection)">
                        <td colspan="3" class="text-start">
                          <div class=" p-1 m-1 text-start">
                            <b>Target Phrase:</b> <span class="badge text-bg-secondary ms-2">{{
                              opportunity.keyword
                            }}</span>
                          </div>
                          <div v-if="currentSection === 'topic' || currentSection ===  'advantage'"
                               class="background-block p-3 m-2">
                            <div class="background-block-heading">Page Comparison</div>
                            <table class="w-100">
                              <tbody>
                              <tr>
                                <td width="35%"><a :href="opportunity.url" target="_blank"
                                                   class="external-link-plain"><img
                                    class="website-select-favicon me-2"
                                    :src="`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://${getDomainFromURL(opportunity.url)}&size=40`">
                                  Your page</a></td>
                                <td class="text-muted"><i>vs</i></td>
                                <td width="55%">
                                  <a :href="chosenOpportunity.url" target="_blank" class="external-link-plain"><img
                                      class="website-select-favicon me-2"
                                      :src="`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://${chosenOpportunity.domain}&size=40`">{{
                                      chosenOpportunity.domain
                                    }}</a>

                                </td>
                              </tr>
                              </tbody>
                            </table>

                          </div>

                          <template v-if="currentSection === 'topic'">

                            <div class="background-block p-3 m-2" v-if="chosenOpportunity">
                              <div class="background-block-heading">What can you lean from {{
                                  chosenOpportunity.domain
                                }}?
                              </div>


                              <div class="d-flex align-items-center flex-row ms-2">
                                <div class="p-2 pe-1 pb-0">
                                  <img class="ps-0 ms-0" width="24"
                                       :src="`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://${chosenOpportunity.domain}&size=24`">
                                </div>
                                <div class="p-2 pb-1">
                                  {{ chosenOpportunity.competitor }}
                                </div>

                              </div>


                              <div class="d-flex align-items-center flex-row ms-2 pb-2">
                                <div class="p-2 pe-1 pb-1 pt-0">
                                  <img class="ps-0 ms-0" width="24"
                                       :src="`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://${getDomainFromURL(opportunity.url)}&size=24`">
                                </div>
                                <div class="p-2 pt-0">
                                  {{ chosenOpportunity.client }}
                                </div>
                              </div>
                              <h4 class="ms-3 mb-1" style="font-size: 11.0pt">Why is this important for
                                {{ opportunity.keyword }}?</h4>
                              <div class="ms-3 mb-2">
                                {{ chosenOpportunity.reason }}
                              </div>

                              <div class="mb-1">
                                <a class="btn btn-sm btn-alt-info" data-bs-toggle="collapse"
                                   href="#collapseExample"
                                   role="button"
                                   aria-expanded="false" aria-controls="collapseExample">
                                  How to answer
                                </a>
                              </div>
                              <div class="collapse" id="collapseExample">
                                <div class="card card-body">
                                  <div class="text-muted mb-1">
                                    <b>{{ chosenOpportunity.recommendation_section }}</b>
                                  </div>
                                  <div class="notification-block-basic p-3 example-content">
                                    <div>{{ chosenOpportunity.recommendation_details }}</div>
                                  </div>
                                </div>
                              </div>

                            </div>

                          </template>
                          <template v-if="currentSection === 'advantage'">
                            <div class="background-block p-3 m-2" v-if="chosenOpportunity">
                              <div class="background-block-heading">Advantages of your page</div>
                              <div class="ms-3 mb-2 ">
                                {{ chosenOpportunity.reason }}
                              </div>
                              <div class="d-flex align-items-center flex-row ms-2">
                                <div class="p-2 pe-1 pb-1 pt-0">
                                  <img class="ps-0 ms-0" width="24"
                                       :src="`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://${getDomainFromURL(opportunity.url)}&size=24`">
                                </div>
                                <div class="p-2 pt-0">
                                  {{ chosenOpportunity.client }}
                                </div>
                              </div>
                              <div class="d-flex align-items-center flex-row ms-2 pb-2">
                                <div class="p-2 pe-1 pb-0">
                                  <img class="ps-0 ms-0" width="24"
                                       :src="`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://${getDomainFromURL(chosenOpportunity.url)}&size=24`">
                                </div>
                                <div class="p-2 pb-1">
                                  {{ chosenOpportunity.competitor }}
                                </div>
                              </div>

                            </div>
                          </template>
                          <template v-if="currentSection === 'question'">

                            <div class="background-block  p-3 m-2" v-if="chosenOpportunity">
                              <div class="background-block-heading">People also ask <span
                                  class="fa fa-ellipsis-v ms-1"></span></div>

                              <div class="accordion accordion-flush w-100 " id="accordionExample"
                              >
                                <div class="accordion-item "
                                     style="border-top:1px solid rgba(128,128,128,0.27); border-bottom:1px solid rgba(128,128,128,0.27)">
                                  <h2 class="accordion-header ">
                                    <button class="accordion-button collapsed p-3" type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded="false"
                                            aria-controls="collapseTwo">
                                      {{ chosenOpportunity.title }}
                                    </button>
                                  </h2>
                                  <div id="collapseTwo" class="accordion-collapse collapse"
                                       data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                      <div class="text-muted">
                                        {{ chosenOpportunity.google_answer }}
                                      </div>
                                      <div>
                                        <a :href="chosenOpportunity.google_url" target="_blank">
                                          <img class="ps-0 ms-0" width="24"
                                               :src="`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://${getDomainFromURL(chosenOpportunity.google_url)}&size=24`">

                                          {{ getDomainFromURL(chosenOpportunity.google_url) }}
                                        </a>
                                      </div>

                                    </div>
                                  </div>


                                </div>
                              </div>

                            </div>

                            <div class="background-block  p-3 m-2" v-if="chosenOpportunity">

                              <div class="background-block-heading"
                                   v-if="chosenOpportunity.article_fit >= 3 && !chosenOpportunity.answered">How to
                                improve your answer
                              </div>
                              <div class="background-block-heading" v-else-if="chosenOpportunity.answered">How you
                                answer this question
                              </div>
                              <div class="background-block-heading" v-else>Why is this question not relevant?</div>

                              <div class="ms-3 mb-4 mt-1">
                                <img class="ps-0 ms-0" width="24"
                                     :src="`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://${getDomainFromURL(opportunity.url)}&size=24`">
                                &nbsp;{{ chosenOpportunity.reason }}
                              </div>
                              <div v-if="chosenOpportunity.article_fit >= 3 && !chosenOpportunity.answered">
                                <h4 class="ms-3 mb-0" style="font-size: 11.0pt">Recommendation</h4>
                                <div class="ms-3 mb-2">
                                  {{ chosenOpportunity.recommendation }}
                                </div>
                                <div class="ms-2 p-2">
                                  <div class="mb-1">
                                    <a class="btn btn-sm btn-alt-info" data-bs-toggle="collapse" href="#collapseExample"
                                       role="button"
                                       aria-expanded="false" aria-controls="collapseExample">
                                      How to answer
                                    </a>
                                  </div>
                                  <div class="collapse" id="collapseExample">
                                    <div class="card card-body">
                                      <div class="text-muted mb-1">
                                        <b>{{ chosenOpportunity.recommendation_section }}</b>
                                      </div>
                                      <div class="notification-block-basic p-3 example-content">
                                        <div>{{ chosenOpportunity.recommendation_details }}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </template>
                        </td>
                      </tr>

                      <tr v-else-if="['audit', 'keywords'].includes(currentSection)">
                        <td colspan="3">
                          <div class=" p-1 m-1 text-start">
                            <b>Target Phrase:</b> <span class="badge text-bg-secondary ms-2">{{
                              opportunity.keyword
                            }}</span>
                          </div>
                          <keywords :keyword-density="keywordDensity" :stats="contentStats"></keywords>


                          <div id="accordion-items"
                               class="text-start pe-1 m-1 p-1 accordion issue-bloc position-relative opportunity-content-items">


                            <div class="accordion-item">
                              <h2 class="accordion-header">

                                <button class="accordion-button issue-header" type="button" data-bs-toggle="collapse"
                                        :class="{'collapsed': false}" :data-bs-target="`#issue-keywords`"
                                >
                                  Important Terms
                                </button>
                                <span
                                    type=""
                                    style="position:absolute;right:55px;top:20px;z-index: 99999;font-size:15pt"
                                    class="fa fa-info-circle"
                                    data-bs-toggle="popover"
                                    data-bs-html="true"
                                    data-bs-placement="left"
                                    title=""
                                    data-bs-content="<div class='alert alert-info m-0'>The terms listed are keywords found on at least two of your competitors' pages.<br>
            For best results you should try to get many of these terms within the target range.<br>
A good approach is to look at the topics to improve upon or questions to answer, and then create content that is relevant to these terms.</div>"
                                ></span>
                              </h2>
                              <div :id="`issue-keywords`" :class="{'show': true}"
                                   class="accordion-collapse collapse issue-body" data-bs-parent="#accordion-items">
                                <div class="accordion-body">
                                  <important-terms :keyword-density="keywordDensity"
                                                   :stats="contentStats"></important-terms>
                                </div>
                              </div>
                            </div>

                            <span
                                v-if="spellingMarks.length || consoleErrors.length  || layoutErrors.filter(e=>e.priority === 'high').length || brokenResources.length || internalRedirectMarks.length || brokenLinkMarks.length || brokenImageMarks.length || combinedSeoIssues.length || missingAltTagImageMarks.length"
                                class="badge text-bg-warning mb-2 mt-2">Page Issues</span>

                            <issue-section id="spelling" title="Spelling Issue" :issues="spellingMarks"></issue-section>

                            <issue-section id="layout" title="Layout Error"
                                           :issues="layoutErrors.filter(e=>e.priority === 'high')"></issue-section>

                            <issue-section id="broken_link" title="Broken Link"
                                           :issues="brokenLinkMarks"></issue-section>

                            <issue-section id="broken_image" title="Broken Image"
                                           :issues="brokenImageMarks"></issue-section>

                            <issue-section id="content_error" title="Content Issue"
                                           :issues="contentErrors"></issue-section>


                            <issue-section id="seo_issue" title="SEO Issue" :issues="combinedSeoIssues"></issue-section>

                            <inner-issue-section id="layout" priority="medium" title="Layout Issue"
                                                 :issues="layoutErrors.filter(e=>e.priority === 'medium')"></inner-issue-section>
                            <issue-section id="content" priority="medium" title="Content Issue"
                                           :issues="contentIssueMarks"></issue-section>

                            <inner-issue-section id="console_error" title="Console Error"
                                                 :issues="consoleErrors"></inner-issue-section>
                            <issue-section id="broken_resource" title="Broken Resource"
                                           :issues="brokenResources"></issue-section>

                            <issue-section id="missing_alt_tag" title="Missing Alt Tag" :issues="missingAltTagImageMarks"></issue-section>


                            <issue-section id="internal_redirect" title="Internal Redirect"
                                           priority="medium" :issues="internalRedirectMarks"></issue-section>

                            <issue-section id="inconsistent_capitalization" title="Capitalization Issue"
                                           priority="low" :issues="inconsistentCapitalizationMarks"></issue-section>

                            <issue-section id="console_warning" title="Warning"
                                           :issues="consoleWarnings"></issue-section>
                          </div>

                        </td>
                      </tr>
                      </tbody>
                    </table>


                  </div>
                </div>
              </div>


            </div>
            <div class="col-12 col-md-4 col-xl-3 max-height-overflow"
                 style="border-left: 1px solid rgba(128, 128, 128, 0.13);">
              <div class="mb-2  mt-2"><b>Ranking Opportunity</b>
              </div>

              <div class="ps-2 pt-0">
                <span class="fa fa-arrow-up-right-dots"></span>
                <router-link
                    class="ms-4"
                    :to="{query: {keywords: 1}}">
                  <span :class="{gap_selected: route.query.keywords}">Content Summary</span>
                </router-link>
              </div>

              <div class="mb-2  mt-4" v-if="gaps.length > 0"><b>Topics to improve upon</b>
              </div>

              <ul
                  class="timeline timeline-alt pt-0 pb-0"
              >
                <!-- Photos Event -->
                <li class="timeline-event" v-for="gap in gaps">
                  <div class="timeline-event-icon ">
                    <img class="ps-0 ms-0 image-timeline" width="30"
                         :src="`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://${gap.domain}&size=28`">
                  </div>
                  <div class="timeline-event-block">
                    <small>
                      <router-link :to="{query: {topic: gap.id}}">
                         <span :class="{gap_selected: gap.selected}">{{ gap.title }}
                      </span>
                      </router-link>
                    </small>
                  </div>


                </li>

              </ul>


              <div class="mb-2 mt-4"  v-if="gaps.length > 0"><b>Topics your page covers better</b></div>
              <div>
                <ul
                    class="timeline timeline-alt pt-0 pb-0"
                >
                  <!-- Photos Event -->
                  <li class="timeline-event" v-for="advantage in advantages">
                    <div class="timeline-event-icon ">
                      <img class="ps-0 ms-0 image-timeline" width="30"
                           :src="`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://${getDomainFromURL(opportunity.url)}&size=28`">
                    </div>
                    <div class="timeline-event-block">

                      <small>
                        <router-link :to="{query: {advantage: advantage.id}}">
                         <span :class="{gap_selected: advantage.id == (route.query.advantage)}">{{ advantage.title }}
                      </span>
                        </router-link>

                      </small>
                    </div>


                  </li>

                </ul>
              </div>
              <div class="mb-2 mt-4"><b>Questions to answer</b></div>
              <div>
                <ul v-if="openQuestions.length > 0"
                    class="timeline timeline-alt pt-0 pb-0"
                >
                  <li class="timeline-event" v-for="question in openQuestions">
                    <div class="timeline-event-icon ">
                      <img class="ps-0 ms-0 image-timeline" width="30"
                           :src="`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://${getDomainFromURL(question.google_url)}&size=28`">
                    </div>
                    <div class="timeline-event-block">

                      <small>
                        <router-link :to="{query: {question: question.id}}">
                         <span :class="{gap_selected: question.id == (route.query.question)}">{{ question.title }}
                      </span>
                        </router-link>

                      </small>
                    </div>


                  </li>

                </ul>

                <div class="ms-3 mb-4 text-muted" v-else>
                  No Unanswered Questions <span class="d-none fa fa-fw fa-circle-question"></span>
                </div>
                <template v-if="answeredQuestions.length > 0">
                  <div class="mt-0 pt-0 ms-3 mb-2" v-show="!showAnsweredQuestions">

                    <small>

                      <a class="link-plain" href="" @click.prevent="showAnsweredQuestions=true"><span
                          class="fa fa-arrow-down-short-wide"></span> <span
                          class="ms-3">{{ answeredQuestions.length }} Answered Questions</span></a>

                    </small>
                  </div>
                  <div v-show="showAnsweredQuestions">
                    <div class="mb-2 mt-1"><b>Answered Questions</b><small><a class="link-plain" href=""
                                                                              @click.prevent="showAnsweredQuestions=!showAnsweredQuestions"><span
                        class="ms-3">[hide]</span></a></small></div>
                    <ul v-if="questions.length > 0"
                        class="timeline timeline-alt pt-0 pb-0"
                    >
                      <li class="timeline-event" v-for="question in answeredQuestions">
                        <div class="timeline-event-icon ">
                          <img class="ps-0 ms-0 image-timeline" width="30"
                               :src="`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://${getDomainFromURL(question.google_url)}&size=28`">
                        </div>
                        <div class="timeline-event-block">

                          <small>
                            <router-link :to="{query: {question: question.id}}">
                         <span :class="{gap_selected: question.id == (route.query.question)}">{{ question.title }}
                      </span>
                            </router-link>

                          </small>
                        </div>


                      </li>

                    </ul>
                  </div>
                </template>
                <template v-if="unRelatedQuestions.length > 0">
                  <div class="mt-0 pt-0 ms-3 mb-2" v-show="!showUnRelatedQuestions">

                    <small>

                      <a class="link-plain" href="" @click.prevent="showUnRelatedQuestions=true"><span
                          class="fa fa-arrow-down-short-wide"></span> <span
                          class="ms-3">{{ unRelatedQuestions.length }} Unrelated Questions</span></a>

                    </small>
                  </div>
                  <div v-show="showUnRelatedQuestions">
                    <div class="mb-2 mt-1"><b>Unrelated Questions</b><small><a class="link-plain" href=""
                                                                               @click.prevent="showUnRelatedQuestions=!showUnRelatedQuestions"><span
                        class="ms-3">[hide]</span></a></small></div>
                    <ul v-if="questions.length > 0"
                        class="timeline timeline-alt pt-0 pb-0"
                    >
                      <li class="timeline-event" v-for="question in unRelatedQuestions">
                        <div class="timeline-event-icon ">
                          <img class="ps-0 ms-0 image-timeline" width="30"
                               :src="`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://${getDomainFromURL(question.google_url)}&size=28`">
                        </div>
                        <div class="timeline-event-block">

                          <small>
                            <router-link :to="{query: {question: question.id}}">
                         <span :class="{gap_selected: question.id == (route.query.question)}">{{ question.title }}
                      </span>
                            </router-link>

                          </small>
                        </div>


                      </li>

                    </ul>
                  </div>
                </template>
              </div>

            </div>

          </div>
          <div class="m-2 mt-0">

            <div class="row ">
              <div class="col col-9">

              </div>
            </div>


          </div>

        </div>
        <div v-else>
          <loading-placeholder style="max-height:600px;height:600px" class="opportunity-load-screen"></loading-placeholder>
        </div>

      </div>
    </div>
  </div>

</template>
<style>
.block-header-rounded {
  background-color: rgba(248, 248, 255, 0.67);
  border-radius: 15px 15px 0px 0px;
}

.block-content-performance {
  padding: 0px;
}

.modal-plain-height {
  height: 200px;
}

@media only screen and (min-width: 768px) {
  .forced-large-modal {
    min-width: 740px
  }
}

@media only screen and (max-width: 1199px) and  (min-width: 768px) {

  .block-content {
    padding-right: 4px;
  }
}

.modal-full-height {
  height: 95vh;
  overflow: hidden;
}


.preview-body {
  .accordion-button {
    background-color: white;
  }

  .accordion-button:not(.collapsed) {
    background-color: #fcfcff; /* color of the track */
    box-shadow: none;

  }

  .accordion-button::after {
    scale: 0.8;
  }

  .accordion-item {
    border: 0;
  }

  .accordion-button:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: transparent !important;
  }
}

.opportunity-load-screen .loading-container{
  height: 600px;
}

span.gap_selected {
  font-weight: 800;
}

.image-timeline {
  border-radius: 10%;
  border: 1px solid rgba(128, 128, 128, 0.16);
}

.example-content h1 {
  font-size: 1.3rem;
  font-weight: 600;
}

.example-content h2 {
  font-size: 1.2rem;
  font-weight: 600;
}

.example-content h3 {
  font-size: 1.1rem;
  font-weight: 600;
}

.opportunity-content {
  margin-right: -20px;
}

.opportunity-content-items{
  max-width: 445px;
}

</style>